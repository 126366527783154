import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text, Span } from "../components/Core";
import Performed from './perfromed.js'
import Combined from './combines.js'
import styled from "styled-components";

const Bookbutton = styled.button`
  position: relative;
  color:#1a1a1a;
  box-shadow: 0 2px 0 -1px #00aec7;
    cursor: pointer;
    transition: color .3s cubic-bezier(.39,.575,.565,1);
    -webkit-text-decoration: none;
    text-decoration: none;
  background:none;
  border:none;
  margin-top:5px;
  padding-top:0px;
  padding-left:0px;
  padding-right:0px;
  font-size:13px;
`;

const NumbersSection = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section  py={0}>
 
      <Container className="">
    

          <Row >

         
              <Col  lg="6" xs="6" className="mb-4 lg-pr-3" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
           <Performed />
              </Col>

              <Col  lg="6" xs="6" className="mb-4 lg-pr-3" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="150">
           <Combined />
              </Col>
           
              
     
         
        </Row>
      </Container>
    </Section>
  </>
);

export default NumbersSection;








