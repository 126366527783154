import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { breakpoints } from "../../utils";
import trust from "../../assets/image/png/trust.svg"
import google from "../../assets/image/png/google-reviews.svg"

import imgC1 from "../../assets/image/png/test1.png";
import imgC2 from "../../assets/image/png/test2.png";
import imgC3 from "../../assets/image/png/test3.png";
import imgC4 from "../../assets/image/png/test4.png";
import imgC5 from "../../assets/image/png/test5.png";
import imgC6 from "../../assets/image/png/test6.png";
import imgC7 from "../../assets/image/png/test7.png";
import imgC8 from "../../assets/image/png/test8.png";

import { device } from "../../utils";

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
text-transform: uppercase;
  color: white;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;


const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="#f7f7fb"
    border="1px solid"
    borderColor="border"
    p="30px"
    
    mx="10px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={3}>
        <img src={image} alt="" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const Testimonial = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    adaptiveHeight: true,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Section py={4} className="pb-md-5  pt-md-5" bg="rgba(0,24,47,.7)"
      
      >
        <Container fluid className="pb-md-5  pt-md-5"
        
        >
          <Row className="justify-content-center">
            <Col md="9" lg="6" className="text-center">
              <Box className="text-center"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
             
              >
                <HeroT>Google <Span color="secondary">REVIEWS</Span>{" "}</HeroT>
              </Box>
            </Col>
          </Row>
        
          <Row className="justify-content-center align-items-center mb-0 mt-3 pb-4">
            <Col lg="11"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="500"
            >
              <SliderStyled {...slickSettings}>

              <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Nicole Smith"
                    image={imgC2}
                  >
                    Mina, thank you so much for the beautiful work you’ve done my chin today. I am honestly so in love with the results it is exactly how I wanted it to look. I had my nose done with Dr Mina and I’m so happy with the results. She is so warm and friendly, I was very comfortable, would recommended 10/10!!!! ❤️
                  </ContentCard>
                </Box>
                <Box
                  css={`
                
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Alyssa Ito"
                    image={imgC1}
                    >
               Mina is amazing at her job! Not only is she professional and highly skilled, but she is also very warm, & welcoming. I can guarantee she will make you feel comfortable & assured throughout any procedure. Not to mention the results she’s able to bring! I highly recommend her to all. 
                  </ContentCard>
                </Box>
               
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Daniela Frangiamore"
                    image={imgC3}
                  >
                         Mina was so lovely and professional, making me feel completely at ease throughout the whole process. I am very happy with both treatments I received and would highly recommend her to anyone who is looking for natural, subtle results.
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Liam Green"
                    image={imgC4}
                  >
                  Mina is super friendly and professional. Excellent at what she does and in the best manner. Happy that I came here and very happy with the result !!
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Sara Jabir"
                    image={imgC5}
                  >
                 I would have no hesitation in recommending Dermamina Aesthetics to friends, family and also people I don't know, from the level of trust Mina has gained from her phenomenal artwork on my face!
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Rashique Siddique"
                    image={imgC6}
                  >
                Fantastic service and great results! Will be recommending to friends and family looking for any non surgical treatments!
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="A Rose"
                    image={imgC7}
                  >
Mina is a true professional, amazing at what she does but also so warm and friendly so you feel comfortable and looked after. Wouldn’t trust anyone else to do my filler x                  </ContentCard>
                </Box>


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Joanna Vijaykumar"
                    image={imgC8}
                  >
Extremely amazing and so professional! Explained everything and all the risks, and even got my mom (who has been trying to talk me out of the year trough fillers) convinced that in a few months she would like to try it! Love the result ! So natural looking!
               </ContentCard>
                </Box>
               
              </SliderStyled>
            </Col>
          </Row>

      

        </Container>
      </Section>
    </>
  );
};

export default Testimonial;
