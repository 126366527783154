import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { rgba } from "polished";
import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
  Span,
} from "../../components/Core";
import Link from 'gatsby-link';
import { device } from "../../utils";

import imgL2HeroOval1 from "../../assets/image/jpeg/d1.jpg";
import imgL2HeroOval2 from "../../assets/image/png/l2-hero-oval2.png";
import imgL2HeroBg from "../../assets/image/jpeg/dermamina-clinic-treatments.jpg";
import imgL3HeroBg from "../../assets/image/jpeg/non-surgical-treatments.jpg";

import nonsurgical from "../../assets/image/jpeg/nonsurgical.jpg";
import nonsurgicalnew from "../../assets/image/jpeg/nonsurgicalnew.jpg";
import trust from "../../assets/image/png/trust.svg"

const Hero = styled.div`
display:none;

@media ${device.lg} {
    background:url(${nonsurgicalnew}) no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100wh;
    height: 100%;
    display: flex;
  }
`;




const Items = styled.div`
flex: 0 0 50%; margin-right: 20px;
`;

const Center = styled.div`

display: flex;
flex-direction: row;
margin-right: 10px;
align-items: stretch;

`;


const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 10px;
      margin-right: 13px;
    }
  }
`;


const Newherro = ({setIsModalOpen}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section py={0}>
     

           <Hero>

               <Container fluid>
                   <Row className="pt-5 pl-5 mt-5 pr-5 mr-5" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false">
                       <Col className="mt-5 mb-5 pt-5 pb-5 " lg="6">
                     
                   <Title color="white" as="h1" ><b>EXPERTS IN <Span color="#00afc9"><br />NON SURGICAL TREATMENTS</Span></b></Title>
                <Text color="white" mb={1} >
                We specialise in non-surgical cosmetic procedures and skincare treatments for both men and women.


                </Text>
                <Box mb={3}>
                    <ULStyled>
                      <li>Leading cosmetics private clinic based in London</li>
                      <li>5,000+ satisfied customers</li>
                    </ULStyled>
                  </Box>
                <Center>
                    <Items>
                  <div onClick={() => setIsModalOpen(true)} ><button type="button" class="fullbtn4">Book an Apppointment <FaArrowRight fontSize="18px" className="align-self-end float-right" /> </button> </div></Items>
                  <Items>  <Link to="about"> <button type="button" class="fullbtn5">Learn More </button> </Link>
                  </Items>
                  </Center>
                       </Col>
                   </Row>
               </Container>
              
             

           </Hero>
                
        
              
         
      </Section>
    </>
  );
};

export default Newherro;