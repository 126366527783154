import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/sunekos/suncontent.js";
import Hero from "../sections/sunekos/sunhero";
import Works from "../sections/vampire/vamwork";
import Steps from "../sections/vampire/vamsteps";
import Clinic from '../sections/nose/nosefind.js';  
import Sess from "../sections/vampire/vamsess";
import Faq from "../sections/sunekos/sunfaq";
import Recovery from "../sections/sunekos/sunrecovery";
import Areas from "../sections/vampire/vamareas";
import Treatment from "../sections/homepage/Treatments.js";
import Brand from "../sections/homepage/Brands.js";
import CTA from '../sections/non/noncta.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Testimonials from "../sections/homepage/Testimonial.js";
import Homehero from "../sections/homepage/homecontent.js";
import RhinoQu from "../sections/rhino/rhinoquestion";
import Banner from "../sections/landing/Abanner";
import Pop from "../sections/landing/popular";

import Saveface from "../sections/landing/saveface";
import Blog from '../sections/landing/bloghome';
import New  from "../sections/homepage/newhero";
import ComboB from "../sections/vampire/combovam";
import Pro from '../sections/landing/stepsprp.js';
import PopupForm from "../components/PopupForm/PopupForm"


const SunekosPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
            <Head title="Sunekos London" image="/images/dermamina-home.jpg"  description="Experts in non surgical nose job Rhinoplasty and skincare treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
       
<Hero  />
<Recovery />
<Testimonial />
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
<Content setIsModalOpen={setIsModalOpen}/>






<Faq setIsModalOpen={setIsModalOpen}/>
<Clinic /> 
<Pop />





<Brand />
 
      



<Saveface />



      </PageWrapper>
      
    </>
  );
};
export default SunekosPage;
