import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import Himage from '../../assets/image/jpeg/derma-building.jpg';

const ImgContainer = styled.div`

  @media ${device.lg} {
    margin-top: 0px;

    position: absolute;
    top: 20%;
   
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;


const Newbut = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-top: auto;
margin-bottom: 4em;
padding-top: 1em;
padding-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
opacity: 0.4;
font-family: azo-sans-web, sans-serif;
color: #fff;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
max-width: 100%;
    display: inline-block;
`;









const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: transparent;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:1;


`;





const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Iline = styled.div`
  width: 6em;
  height: 1px;
  margin-right: 1em;
  background-color: rgb(171, 142, 102);

  @media screen and (max-width: 991px) {
    width: 3em;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(171, 142, 102);
  font-size: 16px;



  font-weight: 600;

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  font-size: 2.8em;

  font-smooth: always;
  margin-bottom: 0.4em;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
  
    justify-content: center;
    text-align: center;
  }
`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color:#999;



font-size: 1em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 10px;
     
     width: 100%;
 }

`;








  


const Secti = styled.section`


position: relative;

background-color: #ffffff;


@media screen and (max-width: 767px)
{

 }

`;




const Sect = styled.section`

  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const NoseFind = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
  
    <Section bg="#ffffff" py={4}
    >
      <Container className="pt-lg-5 pb-lg-5">
       
        <Row className="align-items-center">
      
          <Col data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false" lg="6" xs="6" className="order-lg-1  mt-lg-0">
            <div
              
            >
              <div>
       
              <Iwrap>


<Itext as="h2">Non Surgical Treatments London</Itext>
</Iwrap>
      <SecondText>
      How to find us?
      </SecondText>
                <Thirdtext>
                  <br /> <br />
                Dermamina is based in the heart of London at 114 New Cavendish Street, just off Great Portland Street and the Goodge Street. <br /> <br />

We are accessible by 6 underground stations all within a 5 minute walk, including Oxford Circus underground station. <br /> <br/>

We are just a 4 minute walk from Harley Street. <br /> <br />

Address: 114 New Cavendish St, London W1W 6XT
                </Thirdtext>
               
              </div>
            </div>
          
          </Col>

          <Col data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-once="false" lg="6" xs="6" className="align-items-center text-center justify-content-center  order-lg-2">
            
     <img src={Himage} className="rounded shadow" alt="Non surgical nose job" />
           
           
          </Col>
        </Row>
   
      </Container>
     
    </Section>
   
    
  </>
);

export default NoseFind;
