import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import { Parallax, Background } from 'react-parallax';
import imgMobile from "../../assets/image/jpeg/micro.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'

import Sunone from "../../assets/image/jpeg/sun1.png";
import Sunthree from "../../assets/image/jpeg/sun3.png";



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;









const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const SunContentPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section>

      <Container>
      <Row>
              <Col>
                <Iwrap>


<Itext as="h2">Sunekos UK</Itext>
</Iwrap>
      <SecondText>
      How does Sunekos London work?


      </SecondText>
              </Col>
          </Row>
        <Row>
        <Col lg="6" className="mb-4 pl-0 pr-0 ml-0 ml-0 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="What is non surgical rhinoplasty" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6">
          <Text>
            <b><i>“it’s my secret, I can look and feel younger without anyone knowing I’ve had a treatment “</i></b>
          </Text>
          <Text>
          Turn back the clock and fuel your skin with this revolutionary skin regeneration treatment. Sunekos combines two essential ingredients Hyaluronic Acid and Amino Acids, enabling Cellular Matrix Regeneration (ECM), to stimulate collagen and elastin fibres, helping rebuild and restore your natural beauty. <br /> <br />

Sunekos London is the only natural product in professional dermo-aesthetics to help restore elasticity and volume to the skin by stimulating the synthesis of collagen and elastin. With this scientifically proven game changing treatment, you will see an improvement in wrinkles and signs of ageing, as well as firmer, well hydrated and moisturised skin. <br /> <br />

Collagen and elastin are the essential protein fibers in the skin that give it support and elasticity. As we age those fibers break down leading to fine lines, wrinkles, sagging skin and large pores. Regenerating collagen and elastin can help in getting a more youthful appearance overall.

          </Text>
          </Col>
        </Row>
      </Container>
      <Container>

        
        <Row className="align-items-center">
       
          <Col lg="12" md="9">
            <Box className=" pt-lg-0">

              <Text>

                The production of new collagen and elastin is stimulated through a unique formula of two essential
                ingredients Hyaluronic Acid and Amino Acids through Sunekos injections. Hyaluronic acid and the amino
                acids of collagen and elastin are substances already present in the human body, they are reabsorbed and
                are used for the synthesis of a new dermal matrix, deeply hydrating and plumping treated tissue, leading
                to ‘dermal biogenesis’. Sunekos acts against the process of ageing in a natural way respecting your
                natural face contour. The longevity of the result depends on multiple factors (individual
                characteristics, sun exposure, lifestyle, skin treatments, etc.). <br /> <br />

                There are two types of SuneKos; SuneKos 200 and SuneKos 1200: <br /> <br />

                SuneKos 200 is perfect for earlier signs of ageing and it is used to treat face, around the eyes, neck,
                chest and hands and.
                SuneKos 1200 is used in conjunction with SuneKos 200 if your skin needs extra structural support to
                improve sagging skin or if you have deeper, more advanced wrinkles. Your specific requirements will be
                assessed and discussed with you prior to the treatment.<br /> <br />

                Noticeable improvements after Sunekos treatment include: <br /> <br />

                - Increased firmness and elasticity of the skin<br />
                - Brightness and turgor <br />
                - Hydration to the skins superficial and deep layers <br />
                - Desirable volumizing effect<br />
                - An improvement in fine lines and wrinkles <br /><br />

                Sunekos treatment targets both young, with still elastic and vital skin, to slow down the physiological
                aging process, brighten and boost the skin hydration and for mature skin to reduce the signs of aging,
                reactivating cellular functionality to achieve healthier, tighter and brighter skin.<br /><br />

                <SecondText>
                  How many Sunekos sessions are needed?

                </SecondText>

                <Text>
                  It’s a course of 4 sessions. <br /> <br />

                  There are two protocols for Sunekos treatment; <br /> <br />

                </Text>

                <Text>
                  <b><i>Relatively young skin;</i></b>
                </Text>
                <Text>
                  One treatment cycle of 4 sessions 7 to 10 days apart. Each of the four sessions is an important part
                  of the treatment process and your entire treatment will not be complete until the session is
                  completed. <br /> <br />
                  Results last around 6 months and can be repeated once or twice a year.

                </Text> <br /> <br />

                <img src={Sunone} /> <br /> <br />

                <Text>
                  <b><i>Mature, wrinkled and sagging skin;</i></b>
                </Text>
                <Text>
                  One treatment cycle of 4 sessions, 7 to 10 days apart. On first session a combination of Sunekos 1200
                  and 200 is injected on same treatment session using cushion technique, followed by 3 sessions of
                  Sunekos 200, 7 to 10 days apart. <br /> <br />

                  Each of the four sessions is an important part of the treatment process and your entire treatment will
                  not be complete until the session is completed.
                  Results last around 6 months and can be repeated once or twice a year. <br /> <br />


                </Text>

                <img src={Sunthree} /> <br /> <br />

                <SecondText>
                  Who is suitable for Sunekos London?

                </SecondText>
                <Text>
                  - Ideal for patients who want to improve signs of ageing <br />
                  - Have loose, thin skin and loss of hydration; <br />
                  - Acne scarring<br />
                  - Dark circles under eyes due to thin skin<br />
                  - Fine lines and wrinkles<br />
                  - Improving skin firmness <br /><br />

                </Text>


                <SecondText>
                  Features and benefits

                </SecondText>


                <Text>
                  Sunekos improves the quality of dermis by focusing on providing the essential 6 amino acids and HA
                  needed to stimulate collagen and elastin and boost the ECM. This specific AA combination is capable
                  of:<br /><br />
                  1. Stimulating the fibroblasts in the dermis<br />
                  2. Regenerating new young fibroblasts producing a complete mix of collagens including collagen type IV
                  and elastin<br />
                  3. Free hyaluronic acids molecules are hydrophilic (water loving molecules), which boost skin
                  hydration, tighten the skin and restoring natural volume loss.<br /> <br />

                </Text>


                <SecondText>
                  What can be achieved with Sunekos treatment?

                </SecondText>

                <Text>
                  - Lifting/tightening effect<br />
                  - Reduction of wrinkle severity <br />
                  - Improvement in deep skin hydration <br />
                  - Improvement in skin surface hydration <br />
                  - Improvement in definition <br /> <br />

                </Text>

                <SecondText>
                  Area of treatments

                </SecondText>

                <Text>

                  Sunekos targets both face and body. Treatment can be tailored according to patients needs and areas of
                  concerns.<br /> <br />

                  In the face Sunekos can target the following areas;<br /> <br />
                  • Forehead as its excellent to reduce and improve the appearance of lines and wrinkles without
                  affecting muscle movement.<br />
                  • Cheeks, nasolabial folds to soften signs of aging and add firmness and hydration <br />
                  • Improving pre-jowls areas <br />
                  • Effective in reducing crows feet, dark circles and bags under the eyes caused by thinning of the
                  skin <br />
                  Sunekos can also be injected to improve skin laxity and firmness, reduce the appearance of lines and
                  wrinkles, boost hydration of the hands, neck and decollate <br /> <br />

                </Text>

              </Text>

              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment
                </Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default SunContentPage;
